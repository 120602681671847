import React from "react";

const OurEthics = () => {
  return (
    <>
      {/*==================== Work Process One start ====================*/}
      <div className='work-process-area pd-top-900 pd-bottom-50'>
        <div className='container'>
          <div className='section-title text-center'>
            <h6 className='sub-title'>We Are Awesome </h6>
            <h2 className='title'>
            {/* Driving innovation through <span>new-age</span> technology,  */}
            WHAT MAKES US UNIQUE
            </h2>
          </div>
          <div className='row'>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/icon1.png' alt='img' />
                </div>
                <div className='details'>
                  <p className='process-count'></p>
                  <h5 className='mb-3'>Customer Satisfaction</h5>
                  <p className='content'>
                  Customer satisfaction is our top goal, and it fuels our enthusiasm.</p>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/icon12.png' alt='img' />
                </div>
                <div className='details'>
                  <p className='process-count'></p>
                  <h5 className='mb-3'>Esteem and Reliability</h5>
                  <p className='content'>
                  We take pleasure in our commitment to providing exceptional services.</p>                </div>
                </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/icon13.png' alt='img' />
                </div>
                <div className='details'>
                  <p className='process-count'></p>
                  <h5 className='mb-3'>Business Ambition</h5>
                  <p className='content'>
                  The innovative mindset is what distinguishes us as the best.</p> 
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/icon14.png' alt='img' />
                </div>
                <div className='details'>
                  <p className='process-count'></p>
                  <h5 className='mb-3'>Customized Services</h5>
                  <p className='content'>
                  We are dedicated to providing high-quality, personalized services tailored to your needs                  </p>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/icon15.png' alt='img' />
                </div>
                <div className='details'>
                  <p className='process-count'></p>
                  <h5 className='mb-3'>Simplicity</h5>
                  <p className='content'>
                  Transparency is essential in everything we do and say.</p>                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/icon16.png' alt='img' />
                </div>
                <div className='details'>
                  <p className='process-count'></p>
                  <h5 className='mb-3'>Lifelong Education</h5>
                  <p className='content'>
                  Our developers, designers, and marketers like to push their minds.</p>                
                  </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/icon17.png' alt='img' />
                </div>
                <div className='details'>
                  <p className='process-count'></p>
                  <h5 className='mb-3'>Innovative &Creative</h5>
                  <p className='content'>
                  We promote the ability to see things from a new perspective and encourage you to deliver the best.</p>                
                  </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/icon18.png' alt='img' />
                </div>
                <div className='details'>
                  <p className='process-count'></p>
                  <h5 className='mb-3'>Modern Technology & Strategies</h5>
                  <p className='content'>
                  We use cutting-edge modern technologies and techniques to develop and deliver faster.
                  </p>                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ==================== Work Process One end ====================*/}
    </>
  );
};

export default OurEthics;
