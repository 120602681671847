let serviceList = [
  {
    title: "Mobile App Design/Development",
    des: "Development of innovative mobile apps for expanding your business and increasing product sales.",
    img: "assets/img/service-icon/1.png",
  },
  {
   
    title: "Digital Marketing",
    des: "We use data-driven insights and creative tactics to drive traffic, boost engagement, and achieve your business goals.",
    img: "assets/img/service-icon/2.png",
  },
  {
    title: "Website Design/Development",
    des: "We showcase your precise ideas in the website development and help in elevating your brand value.",
    img: "assets/img/service-icon/3.png",
  },
  {
    title: "Product Development",
    des: "Transforming ideas into innovative products with end-to-end development solutions",
    img: "assets/img/service-icon/2.png",
  },
  {
    title: "Desiging",
    des: "We Will Design logos, cards, banners, posters, stories, etc..",
    img: "assets/img/service-icon/1.png",
  },

  {
    title: "Tech Supports",
    des: "Providing reliable and efficient tech support to keep your systems running smoothly",
    img: "assets/img/service-icon/3.png",
  },
];

export default serviceList;
