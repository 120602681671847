import React from 'react';

const Ecommerce = () => {
  return (
    <div className="ecommerce-section-container">
      <div className="ecommerce-section">
        <div className="ecommerce-text">
          <h2>E-Commerce Web Solutions</h2>
          <p>
          Raxos specializes in creating advanced custom e-commerce websites that look good and are super easy to use. Based in Goa, we make online shopping a breeze for your esteemed customers, boosting your sales effortlessly. 
          </p>
        </div>
        <div className="ecommerce-image">
          <img src="./assets/img/client/ecommerce.jpg" alt="Switch to Digital" />
        </div>
      </div>
      <div className="ecommerce-section">
      <div className="image-container">
        <div className='ecommerce-card'>
          <div className='ecommerce-card-image'>
          <img src="./assets/img/client/ecommerce1.jpg" alt="Switch to Digital"className="e-image" />
          </div>
          </div>
        </div>
        <div className="text-container">
          <h2>Why do you need an E-commerce Web Solution?</h2>
          <p>
          E-commerce provides customers with a convenient, 24/7 shopping experience, offering a wide range of products, detailed information, and seamless transactions, making it a time-saving and personalized experience.
          </p>
        </div>
        </div>  
    </div>
  );
};

export default Ecommerce;
