import React from 'react';
import AboutAreaOne from '../components/AboutAreaOne';
import Breadcrumb from '../components/Breadcrumb';
import ContactAreaOne from '../components/ContactAreaOne';
import CounterAreaOne from '../components/CounterAreaOne';
import FaqAreaOne from '../components/FaqAreaOne';
import FooterOne from '../components/FooterOne';
import NavBar from '../components/NavBar';
import TeamAreaOne from '../components/TeamAreaOne';
import WorkProcessOne from '../components/WorkProcessOne';
import ServiceAreaOne from '../components/ServiceAreaOne';
import AboutTop from "./AboutTop.js";
import AboutBottom from './AboutBottom.js';
import OurEthics from './OurEthics.js';
import BrandAreaOne from "../components/BrandAreaOne";
import TechCategories from './TechSection.js';
import TechSection from './TechSection.js';

const About = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Navigation Bar */}
      <Breadcrumb title={'About Us'} />
    
      {/* About Area One */}
      {/* <AboutAreaOne /> */}
      {<AboutTop></AboutTop>}
      
      {/* ServiceAreaOne */}
      {/* <ServiceAreaOne /> */}
      {<AboutBottom></AboutBottom>}

      {<OurEthics></OurEthics>}

      {/* FAQ Area One */}
      {/* <FaqAreaOne /> */}

      {/* Team Area One */}
      {/* <TeamAreaOne /> */}

      {/* Counter Area One */}
      <CounterAreaOne />
    
      {/* Contact Area One */}
      {/* <ContactAreaOne /> */}
      {<TechSection></TechSection>}

      {/* Work Process One */}
      {/* <WorkProcessOne /> */}

      {/* {<BrandAreaOne></BrandAreaOne>} */}

      {/* Footer One */}
      <FooterOne />
    </>
  );
};

export default About;
