import React from "react";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import serviceList from "../scripts/serviceList";

const ServiceAreaGroup = () => {
  return (
    <>
      {/*=================== service area start ===================*/}
      <div className='about-area pd-top-60'>
        <div className='container'>
          <div className='row'>
            <div
              className='col-lg-6 '
              data-aos='fade-right'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mt-7 mt-lg-1'>
                <h6 className='sub-title'></h6>
                <h3 className='title'>
                <span>WEBSITE DESIGNING & DEVELOPMENT</span>
                </h3>
                <p className='content mb-4 mb-xl-8'>
                We at Raxos, specialize in crafting top-notch websites to boost your online presence. Whether it’s creating a website from scratch, developing on WordPress, 
                diving into CMS territory, or using Shopify, we’ve got you covered.
                </p>
                <ul>
                  <li>Our focus is on building websites that are interactive, appealing and smooth that pull in the traffic for your business growth.  </li>
                  <li>We follow the best practices in web development to ensure your website delivers the right content at the right time to generate leads for your business.</li>
                  <li> Whether your business falls in an industry or corporate setup, is product-based and in web design and development can elevate your business by attracting more customers, enhancing credibility, improving accessibility, and providing an engaging user experience.</li>
                </ul>     
                 <p className='content mb-4 mb-xl-1'>
                  <span>
                 
                  </span>
                 {/* Whether you’re starting a new venture or looking to revamp your existing online presence. Whether your business falls in an industry or corporate setup, is product-based or personal, 
                 investing in web design and development can elevate your business by attracting more customers, enhancing credibility, improving accessibility, and providing an engaging user experience. */}
                  </p>
                  {/* <p>
                  From stunning, user-friendly designs to seamless development, we guarantee an online presence that not only captivates your audience but also drives results. 
                  </p> */}
                 <div className='row'>
                  {/* <div className='col-md-6'>
                    <div className='single-about-inner'>
                      <div className='thumb mb-3'>
                        <img src='assets/img/icon/2.png' alt='img' />
                      </div>
                      <div className='details'>
                        <h5>Our Ambition</h5>
                        <p>
                          Our company specializes in creating innovative
                          designs and delivering exceptional solutions tailored
                          to the needs of businesses across the country.
                        </p>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className='col-md-6'>
                    <div className='single-about-inner'>
                      <div className='thumb mb-3'>
                        <img src='assets/img/icon/3.png' alt='img' />
                      </div>
                      <div className='details'>
                        <h5>Our Approach</h5>
                        <p>
                          Our approach helps find the best IT solutions for business transformation
                        </p>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            
            </div>
            <div className='col-lg-6'>
              <div
                className='about-thumb-inner pe-xl-9   me-xl-9 '
                data-aos='fade-left'
                data-aos-delay='100'
                data-aos-duration='1500'
              >
                {/* <img
                  className='animate-img-1 top_image_bounce'
                  src='assets/img/about/2.png'
                  alt='img'
                /> */}
                  {/* <img
                    className='animate-img-2 left_image_bounce'
                    src='assets/img/about/3.png'
                    alt='img'
                  /> */}
                <img
                  className='animate-img-3 top_image_bounce'
                  src='assets/img/banner/5.svg'
                  alt='img'
                />
                <img
                  className='main-img'
                  src='assets/img/banner/service1.jpg'
                  alt='img'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =================== service area end ===================*/}
    </>
  );
};

export default ServiceAreaGroup;
