import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';

const Card = ({ image, title, link }) => {
  return (
    <div className="card">
      <div className="card-image" >
        <img src={image} alt={title} />
      </div>
      <div className="card-content">
        <h3>{title}</h3>
        <a href={link} className="arrow-link">→</a>
      </div>
    </div>
  );
};

const WorkArea = () => {
  const cards = [
    { image: './assets/img/client/work.png', title: 'MAHESH PAI', link: '#' },
    { image: './assets/img/client/work2.png', title: 'NACHTSTEGEN', link: '#' },
    { image: './assets/img/client/work3.png', title: 'TENDULKAR TYRES', link: '#' },
    { image: './assets/img/client/work3.png', title: 'TENDULKAR TYRES', link: '#' },
    { image: './assets/img/client/work3.png', title: 'TENDULKAR TYRES', link: '#' },
    { image: './assets/img/client/work3.png', title: 'TENDULKAR TYRES', link: '#' },
  ];

  return (
    <>
      <div className="heading-container">
        <h2>Ideas that turned into a reality</h2>
        <p>Our Work</p>
      </div>
      <div className="card-container">
        {cards.map((card, index) => (
          <Card key={index} image={card.image} title={card.title} link={card.link} />
        ))}
        
      </div>
    </>
  );
};

export default WorkArea;
