import React from 'react';


const KeyFactors = () => {
  return (
    <section className='key-container'>
      <div>
        
      <div className="key-factors">
        <div className='Key-background'></div>
        <div>
        <h2 className="key-factors__heading">
        Unlock a world of benefits for your business with our Key Factors!
        </h2>
        </div>
      <div className="key-factors__grid">
        <div className="key-factors__item">
          <div className="key-factors__item-content">
            <img src="./assets/img/service-icon/management-1.png" alt="Well Qualified Team Icon" />
            <h3>Well Qualified Team</h3>
            <p>
              A dedicated team of at least six professionals is allotted for
              every website project, ensuring top-tier service and utmost
              attention for our clients.
            </p>
          </div>
        </div>
        <div className="key-factors__item">
          <div className="key-factors__item-content">
            <img src="./assets/img/service-icon/management-2.png" alt="Lead Generation Icon" />
            <h3>Lead Generation</h3>
            <p>
              Boost your sales with targeted leads through our website
              strategies. We leverage cutting-edge techniques to drive
              qualified leads and maximize your online business potential.
            </p>
          </div>
        </div>
        <div className="key-factors__item">
          <div className="key-factors__item-content">
            <img src="./assets/img/service-icon/management-3.png" alt="High Quality Work Icon" />
            <h3>High-Quality Work</h3>
            <p>
              Experience superior craftsmanship with our skilled web
              developers, who develop high-quality custom designs that make
              your website stand out in today's competitive landscape.
            </p>
          </div>
        </div>
        <div className="key-factors__item">
          <div className="key-factors__item-content">
            <img src="./assets/img/service-icon/management-4.png" alt="After Sales Support Icon" />
            <h3>After Sales Support</h3>
            <p>
              Count on our relentless support. Our committed team is always by
              your side, ready to illuminate any uncertainties and troubleshoot
              challenges—because your success is our priority.
            </p>
          </div>
        </div>
      </div>
    </div>

    </div>
    </section>
  );
};

export default KeyFactors;
