import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = ({ title }) => {
  return (
    <>
      {/* ================ Breadcrumb Start ================*/}
      <div
        className='breadcrumb-area bg-cover pd-top-120'
        // style={{ backgroundImage: 'url("./assets/img/about/about-us07.jpg")' }}
      >
        <div className='container'>
           {/* <div className='col-lg-10 text-lg-center'>
                <ul className='page-list'>
                   <li>
                     <Link to='/index-1'>Home</Link>
                  </li>
                  {"  "}.<li>About</li>
                 </ul>
              </div> */}
          <div className='breadcrumb-inner'>
            <div className='row justify-content-center'>
              <div className='col-lg-11 text-lg-center'>
                <h2 >ABOUT US</h2>
              </div>
              <div className="row">
              <div  className='col-lg-12 text-lg-center'>
                <h4>TECHNOLOGY IS OUR PASSION</h4></div>
            </div>
              </div>
          </div>
        </div>
      </div>
      {/* ================ Breadcrumb End ================*/}
    </>
  );
};

export default Breadcrumb;
