import React from "react";
import MobileBanner from "../components/MobileBanner";
import NavBar from "../components/NavBar";
import Breadcrumb from '../components/Breadcrumb';
import MobileCard from "../components/MobileCard";
import MobileBottom from "../components/MobileBottom";
import MobileBenfit from "../components/MobileBenfit";
import MobileFaqs from "../components/MobileFaqs";
import FooterOne from "../components/FooterOne";
import ContactMain from "../components/ContactMain";

const MobileDevelopement = () => 
    {
        return (
            <>
            {/*Nav Bar */}
            <NavBar />
            {/* Navigation Bar */}
            {/* <Breadcrumb title={'About Us'} /> */}

            {/*Mobile Banner */}
             <MobileBanner />

            {/*Mobile Card */}
            <MobileCard />

            {/*Mobile Bottom */}
            <MobileBottom />

            {/*Mobile MobileBenfit */}
            <MobileBenfit />
            {/*Mobile MobileFaqs */}

            <MobileFaqs />

            {/* Contact Main */}
            <ContactMain />

            {/* Footer One */}
            <FooterOne />
            
            </>
        );
    }

export default MobileDevelopement;