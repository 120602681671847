import React from 'react';
import FooterOne from '../components/FooterOne';
import NavBar from '../components/NavBar';
import ServiceAreaGroup from '../components/ServiceAreaGroup';
import KeyFactors from '../components/KeyFactors';
import WorkArea from '../components/WorkArea';
import Ecommerce from '../components/Ecommerce';
import ContactMain from "../components/ContactMain";

const WebDevelopement = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Navigation Bar */}
      {/* <Breadcrumb title={'Service'} /> */}

      {/* Service Area One */}
      <ServiceAreaGroup />

      {<KeyFactors></KeyFactors>}

      {/* Pricing Area One */}
      {/* <PricingAreaOne /> */}
      {<Ecommerce></Ecommerce>}

      {<WorkArea></WorkArea>}

      {/* Contact Main */}
      <ContactMain />

      {/* Footer One */}
      <FooterOne />
    </>
  );
};

export default WebDevelopement;
