import React, { useRef,useState } from 'react';
import emailjs from '@emailjs/browser';
import axios from 'axios';
import { toast, Toaster } from 'react-hot-toast';
function ContactMain()
{
  const form = useRef();
  // Define state variables using useState
  const [customerName, setCustomerName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerSubject, setCustomerSubject] = useState('');
  const [customerMessage, setCustomerMessage] = useState('');

  const [responseMessage, setResponseMessage] = useState('');
  
  const sendEmail = async (e) => {
    e.preventDefault();
    
    console.log('Send email button clicked');
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL,
        {
          from: process.env.REACT_APP_API_EMAIL_FROM,
          to: process.env.REACT_APP_API_EMAIL_TO,
          subject: 'Contact Form Site',
          htmlbody: `
            Name: ${customerName} <br/>
            Email: ${customerEmail} <br/>
            Message: ${customerMessage}
          `,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: ` Zoho-enczapikey ${process.env.REACT_APP_API_KEY}`, 
            // Include the token in the Authorization header
          },
        }
      );

      if (response.status === 200) {
        setResponseMessage('Email sent successfully!');
        toast.success('Email sent successfully!');
      } else {
        setResponseMessage('Failed to send email. Please try again later.');
        toast.error('Failed to send email. Please try again later.');
      }
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error('Error response:', error.response.data);
        setResponseMessage(`An error occurred: ${error.response.data.message || 'Access denied'}`);
        toast.success(`An error occurred: ${error.response.data.message || 'Access denied'}`);

      } else if (error.request) {
        // Request was made but no response was received
        console.error('No response received:', error.request);
        setResponseMessage('No response received from the server.');
        toast.success('No response received from the server.');

      } else {
        // Something else happened while setting up the request
        console.error('Error message:', error.message);
        setResponseMessage('An error occurred while sending the email.');
        toast.success('An error occurred while sending the email.');

      }
    }
  };
  return (
    <>
      {/* ================= Contact Main start =================*/}
      <Toaster position="bottom-center" reverseOrder={false} />
      <>
        {/* contact area start */}
        <div className="contact-area pd-top-120 pd-bottom-120">
          <div className="container">
            <div className="contact-page-inner bg-gray">
              <div className="section-title mb-5 pb-2">
                <h2 className="title">Ready to Get Started? </h2>
                <p className="content mb-0">
                Your email address will not be published.
                </p>
              </div>
              <form ref={form} onSubmit={sendEmail}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="single-input-inner">
                      <input
                        id="name"
                        name="user_name"
                        type="text"
                        placeholder="Enter Your Name."
                        value={customerName}
                        onChange={(e) => setCustomerName(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="single-input-inner">
                      <input
                        id="email"
                        name="user_email"
                        type="email"
                        placeholder="Enter Your Email."
                        value={customerEmail}
                      onChange={(e) => setCustomerEmail(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="single-input-inner">
                      <input
                        id="subject"
                        name="subject"
                        type="text"
                        placeholder="Enter Your Subject."
                        value={customerSubject}
                      onChange={(e) => setCustomerSubject(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="single-input-inner">
                      <textarea
                        name="message"
                        id="massage"
                        cols="1"
                        rows="5"
                        placeholder="Enter Your Massage ..."
                        value={customerMessage}
                      onChange={(e) => setCustomerMessage(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12 text-center">
                    <button
                      type="submit"
                      className="btn btn-base border-radius-5"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
              {/* {responseMessage && (
      <div className="response-message">
        <p>{responseMessage}</p>
      </div>
    )} */}
            </div>
          </div>
        </div>
        {/* contact area end */}
        {/* contact list start */}
        <div className="contact-page-list">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6">
                <div className="media single-contact-list">
                  <div className="media-left">
                    <img src="assets/img/icon/13.svg" alt="img" />
                  </div>
                  <div className="media-body">
                    <h5>Contacts us</h5>
                    <h6>88 01234 2345 12</h6>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="media single-contact-list">
                  <div className="media-left">
                    <img src="assets/img/icon/14.svg" alt="img" />
                  </div>
                  <div className="media-body">
                    <h5>Your Email</h5>
                    <h6>Comism@mail.com</h6>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="media single-contact-list">
                  <div className="media-left">
                    <img src="assets/img/icon/15.svg" alt="img" />
                  </div>
                  <div className="media-body">
                    <h5>Location</h5>
                    <h6>99 united,America</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* contact list start */}
        {/* map start */}
        <div className="contact-g-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d29208.601361499546!2d90.3598076!3d23.7803374!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1589109092857!5m2!1sen!2sbd"
            title="new title"
          />
        </div>
        {/* map end */}
      </>

      {/* ================= Contact Main End =================*/}
    </>
  );
};

export default ContactMain;
