import React from 'react';
import {
  FaChevronRight,
  FaArrowRight,
  FaCalendarAlt,
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaPhoneAlt,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';

const FooterOne = () => 
  {
  return (
    <>
      {/* ================== Footer One Start ==================*/}
      <footer className="footer-area bg-black mt-0 pd-top-100">
        <div className="footer-subscribe">
          {/* <div className="container">
            <div
              className="footer-subscribe-inner bg-cover"
              style={{ backgroundImage: 'url("./assets/img/bg/6.png")' }}
            >
              <div className="row">
                <div className="col-lg-6">
                  <h2 className="mb-lg-0 mb-3">Subscribe To Our Newsletter</h2>
                </div>
                <div className="col-lg-6 align-self-center text-lg-end">
                  <input type="text" placeholder="Your e-mail address" />
                  <Link className="btn btn-black border-radius-0" to="#">
                    Submit now
                  </Link>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="widget widget_about">
                <div className="thumb">
                  {/* <img src="assets/img/logo2.png" alt="img" /> */}
                
                </div>
                <div className="details">
                  <p>
                  {/* We provide the best of the services in the IT field, achieving exceptional results */}
                  <Link to="#">
                  <FaEnvelope /> info@raxos.com
                  </Link>
                  </p>
                  <ul className="social-media">
                    <li>
                      <Link to="#">
                        <FaFacebookF />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <FaTwitter />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <FaInstagram />
                      </Link>
                    </li>
                    <li>
                      {/* <Link to="#">
                        <FaYoutube />
                      </Link> */}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Our Service</h4>
                <ul>
                  <li>
                    <Link to="/WebDevelopement">
                      <FaArrowRight /> E-commerce
                    </Link>
                  </li>
                  <li>
                    <Link to="/WebDevelopement">
                      <FaArrowRight /> Web design
                    </Link>
                  </li>
                  <li>
                    <Link to="/service-2">
                      <FaArrowRight /> Digital marketing{' '}
                    </Link>
                  </li>
                  <li>
                    <Link to="/MobileDevelopement">
                      <FaArrowRight /> Mobile Design /development
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/service">
                      <FaArrowRight /> Pc Repairs
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/WebDevelopement">
                      <FaArrowRight /> Web Development
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Contact us</h4>
                <ul>
                  <li>
                    <Link to="/about">
                      <FaArrowRight /> About us
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <FaArrowRight /> Blog
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact">
                      <FaArrowRight /> Contact Us{' '}
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/service">
                      <FaArrowRight /> Leadership Team
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link to="/service">
                      <FaArrowRight /> Reasearch sector
                    </Link>
                  </li> */}
                  <li>
                    <Link to="#">
                      <FaArrowRight /> Our Story
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="widget widget_about">
                <div className="thumb">
                  <img src="assets/img/logo6.jpg" alt="img" />
                </div>
                <div className="details">
                  <p>
                  Honing unique processes that delivers exceptional results with breath-taking efficiency to meet revenue goals.                  </p>
                  <p className="mt-3">
                    <FaPhoneAlt /> (+91) 8956346732
                  </p>
                  <p className="mt-2">
                    {/* <FaEnvelope /> (+91) 9878324523 */}
                  </p>
                  <div className="subscribe mt-4">
                    <input type="text" placeholder="E-mail" />
                    <button>
                      <FaChevronRight />
                    </button>
                  </div>
                  <ul className="social-media">
                    {/* <li>
                      <Link to="#">
                        <FaFacebookF />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <FaTwitter />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <FaInstagram />
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link to="#">
                        <FaYoutube />
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-6 align-self-center">
                <p>© Raxos 2024 | All Rights Reserved</p>
              </div>
              <div className="col-md-6 text-lg-end">
                <Link to="#">Trams &amp; Condition</Link>
                <Link to="#">Privacy Policy</Link>
                <Link to="#">Contact Us</Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* ================== Footer One  end ==================*/}
    </>
  );
};

export default FooterOne;
