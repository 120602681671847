import React, { useState, useRef } from 'react';

const TechSection = () => {
  const [activeTab, setActiveTab] = useState('Web Development');

  const categories = {
    'Web Development': ['Angular', 'React', 'JavaScript', 'JQuery', 'HTML5', 'CSS3', 'Bootstrap'],
    'Server technologies': ['PHP', 'Node.Js', 'Laravel', 'Express', 'Python (Django)', 'Spring Boot'],
    'App Development': ['Java', 'Flutter', 'React Native', 'Kotlin'],
    'Tools': ['IntelliJ', 'Eclipse', 'Visual Studio', 'Android Studio', 'X Code'],
    'Design': ['Adobe XD', 'Sketch', 'Canva', 'Figma', 'Adobe illustrator', 'Adobe Photoshop', 'Adobe After Effects', 'Davinci'],
    'Platforms': ['Jira', 'Slack', 'zoom', 'Wordpress', 'Shopify', 'wix'],
    'DevOps/Clouds': ['Gitlab', 'Kubernetes', 'Docker', 'Jenkins', 'Terraform', 'Digital Ocean', 'Hostginger', 'Upcloud'],
    'Database': ['MySQL', 'Mongo', 'Firebase', 'PostgreSQL', 'MariaDB'],
    'Testing': ['postman', 'Burpsuite', 'Jmeter'],
  };
  const icons = {
    //Design
    'Davinci': './assets/img/gallery/davinci.jpg',
    'Adobe Photoshop': './assets/img/gallery/adobe photoshop.png',
    'Adobe After Effects': './assets/img/gallery/adobe after effects.png',
    'Adobe illustrator': './assets/img/gallery/adobe.png',
    Canva: './assets/img/gallery/canva.jpg',
    Sketch: './assets/img/gallery/sketch.png',
    'Adobe XD': './assets/img/gallery/adobe XD.png',
    //Web Developement
    Angular: './assets/img/gallery/angular.png',
    React: './assets/img/gallery/rjs.png',
    JavaScript: './assets/img/gallery/js.png',
    JQuery: './assets/img/gallery/jquery.png',
    HTML5: './assets/img/gallery/html-5.png',
    CSS3: './assets/img/gallery/css3.png',
    Bootstrap: './assets/img/gallery/bootstarp.png',
    //Server Technologies
    PHP: './assets/img/gallery/php.png',
    Java: './assets/img/gallery/java.png',
    Flutter: './assets/img/gallery/flutter.jpg',
    'React Native': './assets/img/gallery/rjs.png',
    Kotlin: './assets/img/gallery/kotlin.png',
    'Node.Js': './assets/img/gallery/node.png',
    Laravel: './assets/img/gallery/Laravel-2.png',
    Express: './assets/img/gallery/express1.png',
    'Python (Django)': './assets/img/gallery/django2.svg',
    'Spring Boot': './assets/img/gallery/spring1.png',
    Python: './assets/img/gallery/python.png',
    //Tools
    IntelliJ: './assets/img/gallery/IntelliJ.png',
    Eclipse: './assets/img/gallery/eclipse.png',
    'Android Studio': './assets/img/gallery/Android_Studio.png',
    'Visual Studio': './assets/img/gallery/visual-studio-code.png',
    'X Code': './assets/img/gallery/xcode.png',
    Figma: './assets/img/gallery/figma.png',
    //Platforms
    Jira: './assets/img/gallery/jira.jpg',
    Slack: './assets/img/gallery/slack.png',
    zoom: './assets/img/gallery/zoom.jpg',
    Shopify: './assets/img/gallery/shopify.png',
    wix: './assets/img/gallery/wix.jpg',
    Wordpress: './assets/img/gallery/wordpress.png',
    //Devopes /Coulds
    Terraform: './assets/img/gallery/terraform.png',
    Jenkins: './assets/img/gallery/jenkins.png',
    Gitlab: './assets/img/gallery/gitlab.png',
    Kubernetes: './assets/img/gallery/kubernetes.png',
    Docker: './assets/img/gallery/Docker.png',
    'Digital Ocean': './assets/img/gallery/digital.png',
    'Hostginger': './assets/img/gallery/hostinger.jpeg',
    Upcloud: './assets/img/gallery/upcloud.jpeg',
    //Database
    MariaDB: './assets/img/gallery/MariaDB.jpeg',
    PostgreSQL: './assets/img/gallery/PostgreSQL.png',
    Firebase: './assets/img/gallery/firebase.jpeg',
    MySQL: './assets/img/gallery/mysql.png',
    Mongo: './assets/img/gallery/mongodb.png',
    //Testing
    postman: './assets/img/gallery/postman.png',
    Burpsuite: './assets/img/gallery/burspuite.jpg',
    Jmeter: './assets/img/gallery/apache jmeter.png',
  };

  const tabsRef = useRef(null);

  const scrollTabs = (direction) => {
    if (tabsRef.current) {
      const scrollAmount = direction === 'left' ? -200 : 200;
      tabsRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  return (
    <div className="tech-section">
      <div className="tech-intro">
        <h2>Technology <span className="highlight">expertise</span></h2>
        <p>
          We believe in delivering digital excellence by applying our domain expertise for your project requirements.
        </p>
      </div>

      <div className="tabs-wrapper">
        <button className="scroll-btn left" onClick={() => scrollTabs('left')}>&lt;</button>
        <ul className="tabs" ref={tabsRef}>
          {Object.keys(categories).map((category) => (
            <li
              key={category}
              className={activeTab === category ? 'active' : ''}
              onClick={() => setActiveTab(category)}
            >
              {category}
            </li>
          ))}
        </ul>
        <button className="scroll-btn right" onClick={() => scrollTabs('right')}>&gt;</button>
      </div>

      <div className="tech-grid">
        {categories[activeTab].map((tech) => (
          <div key={tech} className="tech-item">
            <img src={icons[tech]} alt={tech} />
            <p>{tech}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TechSection;
