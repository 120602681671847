import React from "react";

const AboutTop = () => {
  return (
    <>
      {/* ================== AboutAreaOne start  ==================*/}
      <div className='about-area pd-top-250'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div
                className='about-thumb-inner pe-xl-9 me-xl-9 '
                data-aos='fade-right'
                data-aos-delay='100'
                data-aos-duration='1500'
              >
                {/* <img
                  className='animate-img-1 top_image_bounce'
                  src='assets/img/about/2.png'
                  alt='img'
                /> */}
                  {/* <img
                    className='animate-img-2 left_image_bounce'
                    src='assets/img/about/3.png'
                    alt='img'
                  /> */}
                <img
                  className='animate-img-3 top_image_bounce'
                  src='assets/img/banner/5.svg'
                  alt='img'
                />
                <img
                  className='main-img'
                  src='assets/img/about/about-us02.png'
                  alt='img'
                />
              </div>
            </div>
            <div
              className='col-lg-6 '
              data-aos='fade-right'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mt-5 mt-lg-0'>
                <h6 className='sub-title'>About us</h6>
                <h3 className='title'>
                A differently kindred kind!
                {/* <span>Modern</span> Businesses */}
                </h3>
                <p className='content mb-4 mb-xl-5'>
                Raxos is a IT service provider that delivers versatile solutions by optimizing technology to achieve corporate objectives. We tackle the toughest challenges for businesses with a potent amalgamation of strategic planning, knowledge, and skills to provide high-quality 
                solutions that best fit every client’s technological as well as business needs. </p>
                <p className="content mb-4 mb-xl-5">
                We graciously invest in people and technologies to achieve and maintain the highest levels of expertise and implementation. Unlike many of our competitors, we have a proven track record of success servicing Fortune 50, Inc.
                </p>
                <div className='row'>
                  {/* <div className='col-md-6'>
                    <div className='single-about-inner'>
                      <div className='thumb mb-3'>
                        <img src='assets/img/icon/2.png' alt='img' />
                      </div>
                      <div className='details'>
                        <h5>Our Ambition</h5>
                        <p>
                          Our company specializes in creating innovative
                          designs and delivering exceptional solutions tailored
                          to the needs of businesses across the country.
                        </p>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className='col-md-6'>
                    <div className='single-about-inner'>
                      <div className='thumb mb-3'>
                        <img src='assets/img/icon/3.png' alt='img' />
                      </div>
                      <div className='details'>
                        <h5>Our Approach</h5>
                        <p>
                          Our approach helps find the best IT solutions for business transformation
                        </p>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            
            </div>
            
          </div>
        </div>
      </div>
      {/* ================== AboutAreaOne End  ==================*/}
    </>
  );
};

export default AboutTop;
