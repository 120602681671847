import React from 'react';
import Breadcrumb from '../components/Breadcrumb';
import FooterOne from '../components/FooterOne';
import NavBar from '../components/NavBar';
import PricingAreaOne from '../components/PricingAreaOne';
import ServiceAreaGroupTwo from '../components/ServiceAreaGroupTwo';
import DigitalMarketing from '../components/DigitalMarketing.js';
import DigitalBottom from '../components/DigitalBottom.js';
import ContactMain from "../components/ContactMain";

const ServiceTwo = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {<DigitalMarketing></DigitalMarketing>}

      {/* Navigation Bar
      <Breadcrumb title={'Service 02'} /> */}

      {/* Service Area One */}
      {/* <ServiceAreaGroupTwo /> */}

      {<DigitalBottom></DigitalBottom>}
      
      {/* Pricing Area One */}
      {/* <PricingAreaOne /> */}
      {/* Contact Main */}
      <ContactMain />
      {/* Footer One */}
      <FooterOne />
    </>
  );
};

export default ServiceTwo;
