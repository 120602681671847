import React, { useState } from 'react';
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from 'react-icons/ai'; // Importing icons

const MobileFaqs = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFaq = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faqs">
      <h2 className="faqs-title">FAQs</h2>
      <hr className="faqs-underline" />
      <div className="container">

        {faqData.map((faq, index) => (
          <div
            className={`faq-item ${activeIndex === index ? 'active' : ''}`}
            key={index}
          >
            <div className="faq-question-wrapper" onClick={() => toggleFaq(index)}>
              <div className="faq-question">
                {faq.question}
              </div>
              <div className="faq-icon">
                {activeIndex === index ? (
                  <AiOutlineMinusCircle size={24} />
                ) : (
                  <AiOutlinePlusCircle size={24} />
                )}
              </div>
            </div>
            {activeIndex === index && (
              <div className="faq-answer">
                {faq.answer}
              </div>
            )}
            <hr className="faq-divider" />
          </div>
        ))}
      </div>
    </div>
  );
};

const faqData = [
  {
    question: '1. Why Neetable is the best Mobile app development agency?',
    answer:
      'Our expertise in mobile app development has enabled us to craft a process that ensures high productivity and efficiency. We believe in adapting to new technologies and emerging trends to offer successful mobile applications. Our team will continue to provide support solutions whenever needed.',
  },
  {
    question: '2. How much will it cost to develop mobile app for Android and iOS?',
    answer:
      'This depends on factors like the developer wages, the complexity of the project, estimated project completion duration, and so on. Get in touch with us to know more.',
  },
  {
    question: '3. What is the best mobile app development platform to choose?',
    answer: (
      <>
        <p>This depends on:</p>
        <ul>
          <li>The brand value.</li>
          <li>Who is your target audience?</li>
          <li>What features do you need?</li>
        </ul>
      </>
    ),
  },
  {
    question: '4. What mobile app development services do you provide at Neetable?',
    answer: (
      <>
        <p>Our mobile app development services include:</p>
        <ul>
          <li>Custom iOS and Android app development</li>
          <li>Cross-platform development</li>
          <li>Maintenance and ongoing support</li>
        </ul>
      </>
    ),
  },
  {
    question:
      '5. What is the difference between cross-platform and hybrid mobile app development?',
    answer:
      'Hybrid platforms are used to develop PWAs using HTML5, CSS, etc., which are web technologies. Cross-platform development uses frameworks like React Native, Xamarin, to develop near-native feel apps.',
  },
  {
    question:
      '6. Who owns the source code? Does your company offer documentation for mobile app development services?',
    answer:
      'You have complete ownership of the source code. Our experience as a top mobile app development company enables us to sign an NDA while developing your app. You can count on us to keep your information secure and the development confidential.',
  },
  {
    question:
      '7. Will I be updated on the progress of my application? Can I suggest any changes during the development of the app?',
    answer:
      'Your project manager will inform you about the progress of the application development. You can discuss any changes to the requirements with the project manager.',
  },
];

export default MobileFaqs;
