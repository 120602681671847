import React from "react";

const AboutBottom = () => {
  return (
    <>
      {/* ================== AboutAreaOne start  ==================*/}
      <div className='about-area pd-top-100'>
        <div className='container'>
          <div className='row'>
            <div
              className='col-lg-6 '
              data-aos='fade-right'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mt-5 mt-lg-1'>
                <h6 className='sub-title'>HOW WE DO</h6>
                <h3 className='title'>
                Our Ideology is driven by our passion to deliver excellence!
                {/* <span>Modern</span> Businesses */}
                </h3>
                <p className='content mb-4 mb-xl-5'>
                Raxos' has created a solid foundation for its employees to always go the extra mile. Ours is a dedicated team of technologists and creative marketers who are always enthusiasts for thorough know-how in working for various industries like E-commerce,IT Software & Digital Marketing, Retail. Every professional is committed to going above and beyond to exceed the customers’ expectations</p>     
                 <p className="content mb-4 mb-xl-5">
                 The framework for the functional values with which our decisions, behaviours, and activities are based upon. We guarantee on-time completion, thorough expertise, one-of-a-kind service, and a cost-effective approach to any of your assigned projects.</p>                <div className='row'>
                  {/* <div className='col-md-6'>
                    <div className='single-about-inner'>
                      <div className='thumb mb-3'>
                        <img src='assets/img/icon/2.png' alt='img' />
                      </div>
                      <div className='details'>
                        <h5>Our Ambition</h5>
                        <p>
                          Our company specializes in creating innovative
                          designs and delivering exceptional solutions tailored
                          to the needs of businesses across the country.
                        </p>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className='col-md-6'>
                    <div className='single-about-inner'>
                      <div className='thumb mb-3'>
                        <img src='assets/img/icon/3.png' alt='img' />
                      </div>
                      <div className='details'>
                        <h5>Our Approach</h5>
                        <p>
                          Our approach helps find the best IT solutions for business transformation
                        </p>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            
            </div>
            <div className='col-lg-6'>
              <div
                className='about-thumb-inner pe-xl-9 me-xl-9 '
                data-aos='fade-left'
                data-aos-delay='100'
                data-aos-duration='1500'
              >
                {/* <img
                  className='animate-img-1 top_image_bounce'
                  src='assets/img/about/2.png'
                  alt='img'
                /> */}
                  {/* <img
                    className='animate-img-2 left_image_bounce'
                    src='assets/img/about/3.png'
                    alt='img'
                  /> */}
                <img
                  className='animate-img-3 top_image_bounce'
                  src='assets/img/banner/5.svg'
                  alt='img'
                />
                <img
                  className='main-img'
                  src='assets/img/about/about-us04.png'
                  alt='img'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ================== AboutAreaOne End  ==================*/}
    </>
  );
};

export default AboutBottom;
