import React from "react";
import ProjectAreaOne from "../components/ProjectAreaOne";
import NavBar from "../components/NavBar";
import FooterOne from "../components/FooterOne";
import ContactMain from "../components/ContactMain";

const Portfolio =() =>
{
    return (
        <>
            {/* Navigation Bar */}
            <NavBar />    
            {/*project area*/}
            <ProjectAreaOne />

            {/* Contact Main */}
            <ContactMain />   
             {/* Footer One */}
            <FooterOne />
        </>
    );
};

export default Portfolio;