import React from "react";

const WorkProcessOne = () => {
  return (
    <>
      {/*==================== Work Process One start ====================*/}
      <div className='work-process-area pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='section-title text-center'>
            <h6 className='sub-title'>Innovative Process</h6>
            <h2 className='title'>
            Innovation is our compass, <span>excellence</span> is our path  
            </h2>
          </div>
          <div className='row'>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/9.svg' alt='img' />
                </div>
                <div className='details'>
                  {/* <p className='process-count'>Work 01</p> */}
                  <h5 className='mb-3'>Requirement Analysis</h5>
                  <p className='content'>
                  Requirement analysis is a crucial first step in the development process, setting the foundation for a successful project.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/10.svg' alt='img' />
                </div>
                <div className='details'>
                  {/* <p className='process-count'>Work 02</p> */}
                  <h5 className='mb-3'>Design</h5>
                  <p className='content'>
                  Designing a robust and efficient system is a pivotal phase in the development process, shaping the blueprint for the entire project.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/11.svg' alt='img' />
                </div>
                <div className='details'>
                  {/* <p className='process-count'>Work 03</p> */}
                  <h5 className='mb-3'>Development</h5>
                  <p className='content'>
                  Development involves creating web pages or App Screens with fixed content that remains unchanged unless manually updated.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/12.svg' alt='img' />
                </div>
                <div className='details'>
                  {/* <p className='process-count'>Work 04</p> */}
                  <h5 className='mb-3'>Build & Release</h5>
                  <p className='content'>
                  The Build & Release is a meticulous and essential journey that transforms code into a deployable product.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ==================== Work Process One end ====================*/}
    </>
  );
};

export default WorkProcessOne;
